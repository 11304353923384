import React, {createRef} from "react";

import Dialog from "@material-ui/core/Dialog";

import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {switchAuthDialog} from "../../actions";
import SignUp from "../../containers/SignUp";
import ForgotPassword from "../../containers/ForgotPassword";
import SignIn from "../../containers/SignIn";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});


const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        color: "#1e27ff"
    },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const emailRegex = RegExp(
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
);
class AuthDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            //Login
        };

    }

    // componentWillUnmount() {
    //     this.stopLoginTimer();
    // };

    handleRequestClose = () => {
        this.props.switchAuthDialog({state: false});
    };

    //Login functions ::



    render() {
        // const {} = this.state;
        const {authDialogOpened, authMode} = this.props;
        return (

            <Dialog open={authDialogOpened} onClose={this.handleRequestClose} className="auth-dialog" fullWidth
                // maxWidth={activeStep === 2 ? 'md' : "sm"}
                    maxWidth={"sm"}
            >
                {/*<DialogTitle id="customized-dialog-title" onClose={this.handleRequestClose}/>*/}

                <div className="auth-dialog-inner-wrapper">

                    <div className="auth-logo-wrapper">
                        <img alt={"logo"} src={require("../../assets/images/mars/marslogo.png")}/>
                    </div>
                    {authMode === "login" ?
                        <SignIn/>
                        : authMode === "register" ?
                            <SignUp/>
                            : authMode === "forgetPassword" ?
                                <ForgotPassword/> : ""
                    }
                </div>
            </Dialog>


        )
    }
};

const mapStateToProps = ({auth}) => {
    const {signingIn, tokenReceiveTime, signinStep, signinVerifyDetails, captchaVersion, authDialogOpened, authMode} = auth;
    return {signingIn, tokenReceiveTime, signinStep, signinVerifyDetails, captchaVersion, authDialogOpened, authMode};

};

export default connect(mapStateToProps, {
    switchAuthDialog
})(AuthDialog);

