import React, {useEffect, useRef} from 'react';
import AdminMessageCell from "./adminMessageCell";
import ClientMessageCell from "./clientMessageCell";
import AdminTyping from "./AdminTyping";


const Conversation = ({conversationData, selectedTicket, hashedToken, currentUser}) => {
    let messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        if(messagesEndRef.current)
            messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'end'  });
    }
    useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 100);
    }, [conversationData, selectedTicket.isAdminTyping]);
    return (
        <div className="chat-main-content  " >
            {conversationData.map((conv, index) => conv.is_admin === 0 ?
                <ClientMessageCell key={index} conversation={conv} seen={conv.seen} hashedToken={hashedToken} currentUser={currentUser}/>
               :
                <AdminMessageCell key={index} conversation={conv}  hashedToken={hashedToken}/>

            )}
            <AdminTyping ticket={selectedTicket}/>
            <div id={'last'} ref={messagesEndRef}/>
        </div>
    )
};

export default Conversation;