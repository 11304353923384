import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IntlMessages from "../../../util/IntlMessages";

class CommonQuestions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            openFirst: false,
            openSecond: false,
            openThird: false,
            openForth: false,
            openFifth: false,
            openSixth : false,
            openSeventh: false,
            openEighth: false,
            openNinth: false,
            openTenth: false,
            openEleventh: false,
            openTwelfth: false,
            openThirteenth: false,
            openFourteenth: false,
            openFifteenth: false,
            openSixteenth: false,
            openSeventeenth: false,
            openEighteenth: false,
            openNineteenth: false,

        }
    }


    handleClick = (menuID) => {
        const {openFirst, openThird, openSecond, openForth, openFifth,
            openSixth,
            openSeventh,
            openEighth,
            openNinth,
            openTenth,
            openEleventh,
            openTwelfth,
            openThirteenth,
            openFourteenth,
            openFifteenth,
            openSixteenth,
            openSeventeenth,
            openEighteenth,
            openNineteenth} = this.state;
        switch (menuID) {
            case 1 :
                this.setState({openFirst: !openFirst});
                break
            case 2 :
                this.setState({openSecond: !openSecond});
                break
            case 3 :
                this.setState({openThird: !openThird});
                break;
            case 4 :
                this.setState({openForth: !openForth});
                break;
            case 5 :
                this.setState({openFifth: !openFifth});
                break;
            case 6 :
                this.setState({openSixth: !openSixth});
                break;
            case 7 :
                this.setState({openSeventh: !openSeventh});
                break;
            case 8 :
                this.setState({openEighth: !openEighth});
                break;
            case 9 :
                this.setState({openNinth: !openNinth});
                break;
            case 10 :
                this.setState({openTenth: !openTenth});
                break;
            case 11 :
                this.setState({openEleventh: !openEleventh});
                break;
            case 12 :
                this.setState({openTwelfth: !openTwelfth});
                break;
            case 13 :
                this.setState({openThirteenth: !openThirteenth});
                break;
            case 14 :
                this.setState({openFourteenth: !openFourteenth});
                break;
            case 15 :
                this.setState({openFifteenth: !openFifteenth});
                break;
            case 16 :
                this.setState({openSixteenth: !openSixteenth});
                break;
            case 17 :
                this.setState({openSeventeenth: !openSeventeenth});
                break;
            case 18 :
                this.setState({openEighteenth: !openEighteenth});
                break;
                 case 19 :
                this.setState({openNineteenth: !openNineteenth});
                break;



        }
    };


    render() {
        const {openFirst, openThird, openSecond, openFifth,
            openSixth,
            openSeventh,
            openEighth,
            openNinth,
            openTenth,
            openEleventh,
            openTwelfth,
            openThirteenth,
            openFourteenth,
            openFifteenth,
            openSixteenth,
            openSeventeenth,
            openEighteenth,
            openNineteenth} = this.state;
        return (
            <div className="common-questions-wrapper">
                <div className="common-questions-inner-wrapper">
                    <div className="qa-title">
                        <IntlMessages id="form.description.common-questions"/>
                    </div>
                    <List className="qa-list"
                          component="nav"
                          aria-labelledby="nested-list-subheader"

                    >

                        <>
                            <ListItem button onClick={() => {
                                this.handleClick(6)
                            }} >

                                <ListItemText  primary={<IntlMessages id="common_questions.title.1" />}/>
                                {openSixth ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                            </ListItem>
                            <Collapse in={openSixth} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button >

                                        <ListItemText
                                            primary={<IntlMessages id="common_questions.desc.1" />}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <div className="gradient-bottom"/>
                        </>




                        <>
                            <ListItem button onClick={() => {
                                this.handleClick(10)
                            }} >

                                <ListItemText  primary={<IntlMessages id="common_questions.title.2" />}/>
                                {openTenth ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                            </ListItem>
                            <Collapse in={openTenth} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button >

                                        <ListItemText
                                            primary={<IntlMessages id="common_questions.desc.2" />}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <div className="gradient-bottom"/>
                        </>
                      <>
                          <ListItem button onClick={() => {
                              this.handleClick(11)
                          }}>

                              <ListItemText
                                  primary={<IntlMessages id="common_questions.title.3" />}/>
                              {openEleventh ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                          </ListItem>
                          <Collapse in={openEleventh} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                  <ListItem button >

                                      <ListItemText  primary={<IntlMessages id="common_questions.desc.3" />}/>
                                  </ListItem>
                              </List>
                          </Collapse>
                          <div className="gradient-bottom"/>
                      </>

                        <>
                            <ListItem button onClick={() => {
                                this.handleClick(12)
                            }} >

                                <ListItemText
                                    primary={<IntlMessages id="common_questions.title.4" />}/>
                                {openTwelfth  ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                            </ListItem>
                            <Collapse in={openTwelfth} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button >

                                        <ListItemText
                                            primary={<IntlMessages id="common_questions.desc.4" />}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <div className="gradient-bottom"/>
                        </>

                        <>
                            <ListItem button onClick={() => {
                                this.handleClick(13)
                            }} >

                                <ListItemText
                                    primary={<IntlMessages id="common_questions.title.5" />}/>
                                {openThirteenth ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                            </ListItem>
                            <Collapse in={openThirteenth} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button >

                                        <ListItemText
                                            primary={<IntlMessages id="common_questions.desc.5" />}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <div className="gradient-bottom"/>
                        </>


                        <>
                            <ListItem button onClick={() => {
                                this.handleClick(15)
                            }} >

                                <ListItemText
                                    primary={<IntlMessages id="common_questions.title.6" />}/>
                                {openFifteenth ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                            </ListItem>
                            <Collapse in={openFifteenth} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button >

                                        <ListItemText
                                            primary={<IntlMessages id="common_questions.desc.6" />}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <div className="gradient-bottom"/>
                        </>
                     <>
                         <ListItem button onClick={() => {
                             this.handleClick(16)
                         }} >

                             <ListItemText  primary={<IntlMessages id="common_questions.title.7" />}/>
                             {openSixteenth ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                         </ListItem>
                         <Collapse in={openSixteenth} timeout="auto" unmountOnExit>
                             <List component="div" disablePadding>
                                 <ListItem button >

                                     <ListItemText
                                         primary={<IntlMessages id="common_questions.desc.7" />}/>
                                 </ListItem>
                             </List>
                         </Collapse>
                         <div className="gradient-bottom"/>
                     </>

                        <>
                            <ListItem button onClick={() => {
                                this.handleClick(18)
                            }} >

                                <ListItemText  primary={<IntlMessages id="common_questions.title.8" />}/>
                                {openEighteenth ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                            </ListItem>
                            <Collapse in={openEighteenth} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button >

                                        <ListItemText
                                            primary={<IntlMessages id="common_questions.desc.8" />}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <div className="gradient-bottom"/>
                        </>
                        <>
                            <ListItem button onClick={() => {
                                this.handleClick(19)
                            }} >

                                <ListItemText primary={<IntlMessages id="common_questions.title.9" />}/>
                                {openNineteenth ? <img alt="alt" className="opened" src={require("../../../assets/images/V2/felesh.svg")}/> : <img alt="alt"  src={require("../../../assets/images/V2/felesh.svg")}/>}
                            </ListItem>
                            <Collapse in={openNineteenth} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button >
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<IntlMessages id="common_questions.desc.9" />}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <div className="gradient-bottom"/>
                        </>



                    </List>
                </div>

            </div>
        );
    }

}

export default CommonQuestions;