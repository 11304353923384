import {DIGITAL_CURRENCIES} from "../panel/routes/data/constants";
import {global} from "../store/global";

var
    persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
export const toPersianDate = (unixTime) => {
    return (new Date(unixTime * 1000)).toLocaleString('fa-IR').replace(/([۰-۹])/g, token => String.fromCharCode(token.charCodeAt(0) - 1728)).replace('،', ' ');
};

export const seperateString = (input, space= 4, seperator= '-') => {
    return (input.match(new RegExp('.{1,' + space + '}', 'g')) ?? []).join(seperator);
};

export const toSimplePersianDate = (unixTime) => {
    let date = (new Date(unixTime * 1000)).toLocaleString('fa-IR').replace(/([۰-۹])/g, token => String.fromCharCode(token.charCodeAt(0) - 1728)).replace('،', ' - ');
    return date.substring(0, date.indexOf(' '));
};

export const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0;
};

export const clearCommas = (localized) => {
    return localized.toString().split(',').join('');
};

export const isCryptoCurrency = (currencyID) => {
    return ![
        DIGITAL_CURRENCIES.PM_USD,
        DIGITAL_CURRENCIES.PV_USD,
        DIGITAL_CURRENCIES.PM_EURO,
        DIGITAL_CURRENCIES.PV_EURO,
        DIGITAL_CURRENCIES.WM,
        DIGITAL_CURRENCIES.PAYMER,
        DIGITAL_CURRENCIES.IRR,
        DIGITAL_CURRENCIES.PS_VOUCHER,
        DIGITAL_CURRENCIES.PAYEER,
        DIGITAL_CURRENCIES.GOLD,
        DIGITAL_CURRENCIES.UV_USD,
        DIGITAL_CURRENCIES.UUSD,
        DIGITAL_CURRENCIES.CRP,
        DIGITAL_CURRENCIES.UV_CRP,
        DIGITAL_CURRENCIES.USDT_TRC20,
        DIGITAL_CURRENCIES.TRX_TRC20,
    ].includes(parseInt(currencyID))
};

export const isCrypto = (currencyID) => {
    return isCryptoCurrency(currencyID) && currencyID != DIGITAL_CURRENCIES.USDT;
};

export const isPerfectFork = (currencyID) => {
    return [
        DIGITAL_CURRENCIES.PM_USD,
        DIGITAL_CURRENCIES.PM_EURO,
        DIGITAL_CURRENCIES.PV_USD,
        DIGITAL_CURRENCIES.PV_EURO,
    ].includes(currencyID);
};

export const isWebMoneyFork = (currencyID) => {
    return [
        DIGITAL_CURRENCIES.WM,
        DIGITAL_CURRENCIES.PAYMER,
    ].includes(currencyID);
};

export const fa2enNumbers = (str) => {
    let converted = str.toString();
    for(let i=0; i<10; i++)
        converted = converted.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);

    return converted;
};

export const translate = (translations, key, lang = global.locale.locale) => {
    if(lang === 'fa')
        return translations[key] ? translations[key][lang] : ' - ';
    else
        return key;
}

export const currencySymbol = (currencies, currencyID) => {
    return currencies[currencyID] ? currencies[currencyID]['symbol'] :'-'
}

const tradeStep2 = (currencies, currencyID) => {
    return currencies[currencyID] ? currencies[currencyID]['trade_step'].toFixed(8) :'0'
}

export const tradeStep = tradeStep2;

export const stepPrecision = (currencies, currencyID) => {
    let tradeStep = tradeStep2(currencies, currencyID);
    // console.log('tradeStep for', currencyID, currencies[currencyID], tradeStep)
    return (tradeStep.toString().substring(tradeStep.toString().indexOf('.')).indexOf('1') ?? -1)
}

export const prettyCurrencAmount = (currencyID, amount) => {
    if(currencyID === DIGITAL_CURRENCIES.IRR)
        return parseInt(amount / 10).toLocaleString();
    else
        return (+amount).toLocaleString();
}

export const getCurrencySupply = (originCurrency) => {
    if([DIGITAL_CURRENCIES.PM_USD, DIGITAL_CURRENCIES.PV_USD].includes(originCurrency))
        return DIGITAL_CURRENCIES.PM_USD;
    if([DIGITAL_CURRENCIES.UUSD, DIGITAL_CURRENCIES.UV_USD].includes(originCurrency))
        return DIGITAL_CURRENCIES.UUSD;
    if([DIGITAL_CURRENCIES.WM].includes(originCurrency))
        return DIGITAL_CURRENCIES.WM;
    if([DIGITAL_CURRENCIES.USDT_TRC20].includes(originCurrency))
        return DIGITAL_CURRENCIES.USDT_TRC20;
    if([DIGITAL_CURRENCIES.TRX_TRC20].includes(originCurrency))
        return DIGITAL_CURRENCIES.TRX_TRC20;
    if([DIGITAL_CURRENCIES.IRR].includes(originCurrency))
        return DIGITAL_CURRENCIES.IRR;
    return DIGITAL_CURRENCIES.USDT;

}

export const getSupplyWage = (supplyCurrency) => {
    if([DIGITAL_CURRENCIES.PM_USD].includes(supplyCurrency))
        return {min: 0, max: 0, rate: 1};
    if([DIGITAL_CURRENCIES.UUSD].includes(supplyCurrency))
        return {min: 0, max: 0, rate: 0.15};
    if([DIGITAL_CURRENCIES.WM].includes(supplyCurrency))
        return {min: 0, max: 0, rate: 0.15};
    if([DIGITAL_CURRENCIES.USDT_TRC20].includes(supplyCurrency))
        return {min: 1.5, max: 1.5, rate: 0};
    if([DIGITAL_CURRENCIES.TRX_TRC20].includes(supplyCurrency))
        return {min: 1.1, max: 30, rate: 0.0025};
    if([DIGITAL_CURRENCIES.USDT].includes(supplyCurrency))
        return {min: 1, max: 1, rate: 0};
    return {min: 0, max: 0, rate: 0};

}

export const calculateSupplyWage = (supplyCurrency, amount) => {
    let supplyWage = getSupplyWage(supplyCurrency);
    let ratedFee = supplyWage.rate * amount;
    return +Math.min(Math.max(supplyWage.min, ratedFee), supplyWage.max)

}

export const roundUp = (amount, precision) => {
    let multiplier = Math.pow(10, precision);
    return Math.ceil(amount * multiplier) / multiplier;
}

export const roundDown = (amount, precision) => {
    let multiplier = Math.pow(10, precision);
    return Math.floor(amount * multiplier) / multiplier;
}





