import React from 'react';
import TicketCell from "./TicketCell/index";

const TicketChatList = ({tickets, selectedSectionId, onSelectUser}) => {
  // console.log('tickets', tickets.length)
  return (
    <div className="chat-user">
      {tickets.map((ticket, index) =>
        <TicketCell key={index} ticket={ticket} selectedSectionId={selectedSectionId} onSelectUser={onSelectUser}/>
      )}
    </div>
  )
};
export default TicketChatList;