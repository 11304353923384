// import Swiper core and required modules
import {Autoplay, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import React from "react";
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/thumbs/thumbs.min.css';
// import 'swiper/swiper-bundle.css';
import {Card} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {MOBILE_MAX_WIDTH} from "../../../panel/routes/data/constants";
import {authModeSelector, switchAuthDialog} from "../../../actions";
import IntlMessages from "../../../util/IntlMessages";


class GreetingSlider extends React.Component{
    render(){
        const {width} = this.props;
        return(
            <Swiper
                modules={[ Autoplay]}
                pagination={width < MOBILE_MAX_WIDTH ? false : (true, {clickable: true})}
                autoplay
                className="swiper"
                // pagination={{
                //     clickable: true,
                // }}

            >
                <SwiperSlide>
                    <Card className="swiper-card">
                        <div className="heading">
                            <IntlMessages id='greetings.slide1.title'/>

                        </div>
                        <div className="text">
                            <IntlMessages id='greetings.slide1.desc'/>
                        </div>

                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className="swiper-card">
                        <div className="heading">
                            <IntlMessages id='greetings.slide2.title'/>
                        </div>
                        <div className="text">
                            <IntlMessages id='greetings.slide2.desc'/>
                        </div>

                    </Card>
                </SwiperSlide>
                {/*<SwiperSlide>*/}
                {/*    <Card className="swiper-card">*/}
                {/*        <div className="heading">*/}
                {/*            چنجینی  نماینده رسمی پرفکت‌مانی در ایران می‌باشد*/}
                {/*        </div>*/}
                {/*        <div className="text">*/}
                {/*            ارائه خدمات انتقال دلار و یورو پرفکت‌مانی، بدون کارمزد و در نهایت سرعت، جرئی از خدمات ماست*/}
                {/*        </div>*/}
                {/*        <div className="slider-btn-wrapper">*/}
                {/*            <a href="https://irexchanger.org/buy-perfect-money/" target='_blank' className="sign-up-btn">*/}
                {/*                خرید پرفکت‌ مانی*/}
                {/*            </a>*/}
                {/*            <Link to="panel" className="login-btn">*/}
                {/*                ورود*/}
                {/*            </Link>*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*    <Card className="swiper-card">*/}
                {/*        <div className="heading">*/}
                {/*            چنجینی  نماینده رسمی وب‌مانی و صادرکننده پاسپورت وب‌مانی در ایران می‌باشد*/}
                {/*        </div>*/}
                {/*        <div className="text">*/}
                {/*            آماده ارائه خدمات صدور اینیشیال پاسپورت و شارژ وب‌مانی در حساب بروکر شما هستیم*/}
                {/*        </div>*/}
                {/*        <div className="slider-btn-wrapper">*/}
                {/*            <a href="https://irexchanger.org/buy-webmoney/" target='_blank' className="sign-up-btn">*/}
                {/*                خرید وب‌مانی*/}
                {/*            </a>*/}
                {/*            <Link to="panel" className="login-btn">*/}
                {/*                ورود*/}
                {/*            </Link>*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*</SwiperSlide>*/}

            </Swiper>
        )
    }
}

const mapStateToProps = ({settings}) => {
    const {width} = settings;
    return {width}
};
export default withRouter(connect(mapStateToProps, {switchAuthDialog, authModeSelector})(GreetingSlider));