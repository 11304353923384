import React from 'react';
import IntlMessages from "../../../../util/IntlMessages";
import {STATUS} from "../../../../panel/routes/data/constants";
import {toPersianDate} from "../../../../util/Utilities";
import {MANAGEMENT_UNITS} from "../../../routes/data/constants";

import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const SUPPORT_IMAGES = {
    3: <img src={require("../../../../assets/images/mars/setting-2-svgrepo-com.svg")}/>,
    5: <img src={require("../../../../assets/images/mars/message-notif-svgrepo-com.svg")}/>,
    4: <img src={require("../../../../assets/images/mars/dollar-square-svgrepo-com.svg")}/>,
};


const TicketCell = ({ticket, selectedSectionId, onSelectUser}) => {

    const createTicketStatusBadge = (status) => {
        if (status === STATUS.ACCEPTED_BY_USER )
            return <span className="success-text">
                <IntlMessages id="status.support.accepted_by_user"/>
            </span>


        else if (status === STATUS.ACCEPTED_BY_ADMIN )
            return <span className="success-text">

                    <IntlMessages id="status.support.accepted_by_admin"/>
            </span>
        else if (status === STATUS.ADMIN_PROGRESS )
            return <span className="warning-text">
              <IntlMessages id="status.support.admin_progress"/>
            </span>
        else if (status === STATUS.USER_PROGRESS )
            return <span className="success-text">
              <IntlMessages id="status.support.user_progress"/>
            </span>
        else if (status === STATUS.SYSTEM_PROGRESS )
            return <span className="warning-text">


                    <IntlMessages id="status.support.system_progress"/>
            </span>
        else if ([STATUS.REJECTED_BY_ADMIN, STATUS.REJECTED_BY_USER, STATUS.REJECTED_BY_SYSTEM].includes(status))
            return <span className="danger-text">
                <IntlMessages id="status.support.closed"/>
                  </span>

    }

    return (
        <>
            <div key={ticket.id} className={`chat-user-item ${selectedSectionId === ticket.id ? 'active' : ''}`}
                 onClick={() => {
                     onSelectUser(ticket);
                 }}>
                <div className="chat-user-row">
                    <div className="chat-unit-wrapper">
                        {SUPPORT_IMAGES[ticket.management_unit]}
                        <div className="unit-title">{MANAGEMENT_UNITS[ticket.management_unit]} </div>
                    </div>
                    <div className="chat-desc-wrapper">
                    <div className="chat-desc">{ticket.title.substring(0, 40) + "..."}</div>

                    </div>
                    <div className="chat-additional-info">
                        <div className="date-wrapper">
                            {toPersianDate(ticket.created_at)}
                        </div>
                        <div className="status-wrapper">
                            {createTicketStatusBadge(parseInt(ticket.status.toString()))}
                        </div>
                    </div>
                </div>

            </div>

        </>
    )

};




export default TicketCell;