import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Sidebar from 'containers/OurSideNav/index';

import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION,} from '../constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import Footer from "../containers/components/Footer";
import Header from "../containers/components/MainHeader";
import LiveChat from "./components/LiveChat";
import Dialog from "@material-ui/core/Dialog";
import DialogTab from "./routes/wallet/deposit/DepositTabs";
import WithdrawDialog from "./routes/wallet/withdarw/withdrawDialog";
import {switchDepositDialog, switchWithdrawDialog} from "../actions/Wallet";
import DepositDialog from "./routes/wallet/deposit/depositDialog";


const PANEL_PATH = 'panel';

class Panel extends React.Component {

  componentDidMount() {

  }

  handleRequestCloseWithdrawDialog = () => {
    this.props.switchWithdrawDialog({state: false});
  };

  render() {
    const {match, location, token, initURL, drawerType, navigationStyle, darkTheme,
      isContentFixed, withdrawDialogOpened} = this.props;
    if (['/' + PANEL_PATH + '/', '/' + PANEL_PATH].includes(location.pathname)) {
      if (token === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (['/' + PANEL_PATH + '/', '/' + PANEL_PATH].includes(location.pathname)) {
        return ( <Redirect to={location.pathname + (location.pathname.lastIndexOf('/') === 0 ? '/' : '') + 'dashboard'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (

        <div className={`app-container ${drawerStyle}`}>

          <Sidebar/>
          <div className="app-main-container">


            <div
                className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
              {/*{(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&*/}
              {/*<TopNav styleName="app-top-header"/>}*/}
              <Header/>
              {/*{(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&*/}
              {/*<TopNav/>}*/}
            </div>
            <main className="app-main-content-wrapper" style={{overflowY: (isContentFixed ? 'hidden' : 'auto')}}>
              <div className="app-main-content panel-content-wrapper" style={{overflowY: (isContentFixed ? 'unset' : 'auto'), overflowX: 'hidden'}}>
                {/*<SystemNotice url={location.pathname}/>*/}
                {/*<LiveChat/>*/}
                <Switch>
                  <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./routes/dashboard/dashboard'))}/>
                  <Route path={`${match.url}/exchange`} component={asyncComponent(() => import('./routes/exchange/index'))}/>
                  <Route path={`${match.url}/Wallet`} component={asyncComponent(() => import('./routes/wallet'))}/>
                  <Route path={`${match.url}/CreditCard`} component={asyncComponent(() => import('./routes/creditcard/creditcard'))}/>
                  <Route path={`${match.url}/Support`} component={asyncComponent(() => import('./routes/support/support'))}/>
                  <Route path={`${match.url}/Authorization`} component={asyncComponent(() => import('./routes/authorization'))}/>
                  <Route path={`${match.url}/Setting`} component={asyncComponent(() => import('./routes/Setting'))}/>
                  <Route path={`${match.url}/Notices`} component={asyncComponent(() => import('./routes/notices/notices'))}/>
                      <Route path={`${match.url}/api-management`} component={asyncComponent(() => import('./routes/apiManagement'))}/>

                </Switch>
              </div>
              <DepositDialog/>
              <WithdrawDialog/>

              <Footer/>
            </main>
          </div>

        </div>
    );
  }
}


const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition, isContentFixed, darkTheme} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition, isContentFixed, darkTheme}
};
export default withRouter(connect(mapStateToProps, {switchDepositDialog, switchWithdrawDialog})(Panel));