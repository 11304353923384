import React from "react";
import "./../../../assets/css/widget.css"
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {Dialog, TextField} from "@material-ui/core";
import {submitTicket, switchComposeDialog} from "../../../actions/SupportResource";
import IntlMessages from "../../../util/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {fa2enNumbers} from "../../../util/Utilities";
import {lengthBetween} from "../../../util/Validator";
import {DropzoneArea} from "material-ui-dropzone";
import LinearProgressWithLabel from "../../../panel/routes/components/linearProgressWithLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {showError} from "../../../panel/components/Notifier";
import {SUPPORT} from "../../../constants/Units";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {global} from "../../../store/global";

const MAX_MESSAGE_LENGTH = 2048;

const management_units = [
    {
        value: "3",
        label: <IntlMessages id='title.support_unit.tech'/>
    },
    {
        value: "4",
        label: <IntlMessages id='title.support_unit.finance'/>
    },
    {
        value: "5",
        label: <IntlMessages id='title.support_unit.criticisms'/>
    }
];

const acceptableExtensions = ['jpeg', 'png', 'jpg', 'pdf', 'rar', 'zip'];

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class ComposeDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            open: props.submittingTicket,
            inputs: {
                management_unit: 3,
                subject: "",
                message: "",
                file: ""
            },
            formErrors: {
                management_unit: "",
                subject: "",
                message: "",
                file: ""
            },
        };
    }


    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.open && !nextProps.submittingTicket && this.props.submittingTicket && !nextProps.hasErrors)
            this.props.switchComposeDialog(true);

    }

    handleClickOpen = () => {
        // this.setState({open: true});
        this.props.switchComposeDialog(true);
    };

    handleRequestClose = () => {
        this.props.switchComposeDialog(false);
    };

    setFile(files) {
        this.setState({
            file: files[0]
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        const {management_unit, subject, message} = this.state.inputs;
        if (this.formValidated()) {
            formData.append('title',subject);
            formData.append('message', message);
            formData.append('management_unit', management_unit);
            if(this.state.file){
                formData.append('file', this.state.file);
            }

            this.props.submitTicket(formData);

        } else
            showError(SUPPORT, 'form.invalid');

    };

    formValidated = () => {
        const {inputs} = this.state;
        const context = this;
        let isValid = true;
        Object.keys(inputs).map(function(key, index) {
            isValid &= context.validateFormValue(key, inputs[key]);
        });
        const {formErrors} = this.state;
        Object.keys(formErrors).map(function(key, index) {
            isValid &= !formErrors[key];
        });
        return isValid;
    };

    validateFormValue = (name, value) => {
        value = fa2enNumbers(value);
        let {formErrors, inputs} = this.state;
        switch (name) {
            case 'subject':
                if (!lengthBetween({input: value, min: 2, max: 128}))
                    formErrors.subject = <IntlMessages id="error.content.subject.length"/>;
                else
                    formErrors.subject = "";
                break;

            case 'message':
                value = value.substring(0, MAX_MESSAGE_LENGTH);
                break;

        }
        inputs[name] = value;
        this.setState({formErrors, inputs});
        return !formErrors[name];
    };

    handleChange = e => {
        const {name, value} = e.target;
        // this.setState({[e.target.name]: e.target.value});
        this.validateFormValue(name, value);

    };

    render() {
        const {inputs, formErrors} = this.state;
        const {submittingTicket, progress, composeDialogOpened } = this.props;
        return (
            <>
                <div className="col-lg-11 m-auto confirm-btn-wrapper">
                    <Button fullWidth variant="contained" className="confirm-btn" onClick={this.handleClickOpen}>
                        <span>+ ارسال تیکت جدید</span>
                    </Button>
                </div>

                <Dialog fullWidth onClose={this.handleRequestClose} open={composeDialogOpened}
                       style={{zIndex: 2600}}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleRequestClose}/>
                    <div className="compose-dialog-wrapper">
                        <div className="title">
                            ایجاد تیکت جدید
                        </div>
                        {submittingTicket ? <LinearProgressWithLabel progress={progress}/> : ""}
                        <div className="compose-form-wrapper">
                            <div className="main-form-wrapper">
                                <div className="input-wrapper">
                                    <TextField
                                        label={<IntlMessages id="form.description.select_ticket_department"/>}
                                        select
                                        onChange={this.handleChange}
                                        name="management_unit"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={inputs.management_unit}
                                    >

                                        {management_units.map((option) => { return (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        )}) }

                                    </TextField>
                                </div>
                                <div className="input-wrapper">
                                    <TextField
                                        label={<IntlMessages id="title.subject" />}
                                        onChange={this.handleChange}
                                        fullWidth
                                        variant="outlined"
                                        name= "subject"
                                        size="small"
                                        inputProps={{
                                            style: {textAlign: 'right'}
                                        }}
                                        error={!!formErrors.subject}
                                        className={formErrors.subject ? "error" : null}
                                        value={inputs.subject}
                                    />
                                    {formErrors.subject && (
                                        <div className="error-box">
                                            <img alt="alt" src={require("../../../assets/images/V2/error.svg")}/>
                                            {formErrors.subject}
                                        </div>
                                    )}
                                </div>
                                <div className="text-area-wrapper">
                                    <TextField
                                        label={<IntlMessages id="form.description.ticket_message" />}
                                        onChange={this.handleChange}
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rowsMax="8"
                                        rows="5"
                                        name= "message"
                                        size="small"
                                        error={!!formErrors.message}
                                        className={formErrors.message ? "error" : null}
                                        value={inputs.message}
                                    />
                                    {formErrors.message && (
                                        <div className="error-box">
                                            <img alt="alt" src={require("../../../assets/images/V2/error.svg")}/>
                                            {formErrors.message}
                                        </div>
                                    )}


                                </div>

                            </div>
                            <div className="upload-wrapper">
                                <div className="upload-item">
                                    <DropzoneArea
                                        onChange={this.setFile.bind(this)}
                                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg', '.pdf', '.rar', '.zip']}
                                        maxFileSize={20971520}
                                        filesLimit={1}
                                        useChipsForPreview={true}
                                        dropzoneText={<span
                                            className="jr-fs-lg text-light-grey"> <IntlMessages
                                            id="form.description.ticket_attachment"/></span>}
                                        getFileAddedMessage={(fileName => `${fileName} ` + (global.locale.locale === 'fa' ? 'انتخاب شد.' : "Selected"))}
                                        getFileRemovedMessage={(fileName) => `${fileName} ` + (global.locale.locale === 'fa' ? 'حذف شد.' : 'Deleted')}
                                        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                                            return (
                                                <div>
                                                    <p className='mb-1'>{global.locale.locale === 'fa' ? `انتخاب ${rejectedFile.name} با خطا مواجه شد.` : `Error on selecting ${rejectedFile.name}`}</p>
                                                    <p className='mb-1'>{(global.locale.locale === 'fa' ? 'فرمت های قابل ارسال' : 'Allowed formats')}: {acceptableExtensions.join(', ')}</p>
                                                    <p className='mb-1'>{(global.locale.locale === 'fa' ? 'حداکثر حجم مجاز فایل' : 'Max allowed size')}: {maxFileSize / 1024 / 1024}</p>
                                                </div>)
                                        }}
                                    />


                                </div>
                                <div className="button-wrapper">

                                    <Button
                                        variant="contained"
                                        disabled={submittingTicket}
                                        onClick={this.handleSubmit}
                                        fullWidth
                                        className="confirm-btn"
                                    >
                                    {submittingTicket ? <><IntlMessages id="button.sending"/> <CircularProgress size={24} /></> : <IntlMessages id="button.submit_ticket"/>}
                                    </Button>
                                </div>
                            </div>

                        </div>
                        <div  className={'ltr text-limit'} style={{textAlign: 'right'}}>{MAX_MESSAGE_LENGTH - inputs.message.length} / {MAX_MESSAGE_LENGTH}</div>


                    </div>
                    {/*<div className="modal-box-content d-flex flex-column">*/}
                    {/*    <ComposeForm />*/}
                    {/*</div>*/}
                </Dialog>
            </>

        )
    }
}

const mapStateToProps = ({support, commonData}) => {
    const {submittingTicket, composeDialogOpened, progress} = support;
    const {hasErrors} = commonData;
    return {submittingTicket, hasErrors, composeDialogOpened, progress}
};

export default connect(mapStateToProps, {switchComposeDialog, submitTicket})(ComposeDialog);

