import React, {createRef} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import '../assets/css/formStyle.css';
import classNames from "classnames";
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import {withStyles} from "@material-ui/core/styles";
import {isMobile, isNumeric, lengthBetween} from "../util/Validator";
import {showError} from "../panel/components/Notifier";
import ReactCodeInput from "react-code-input";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    authModeSelector,
    resendForgetPassword,
    setForgotPasswordStep,
    updateCaptchaVersion,
    userForgetPassword,
    verifyUserForgotPassword,
} from "../actions";
import IconButton from "@material-ui/core/IconButton";
import {FORGETPASSWORD, LOGIN} from "../constants/Units";
import RefreshIcon from "@material-ui/icons/Refresh";
import {fa2enNumbers} from "../util/Utilities";
import BrowserAlert from "./components/BrowserAlert";
import {BRANCH_ESSENTIALS} from "../panel/routes/data/constants";
import {global} from "../store/global";


const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        color: grey[50],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


const emailRegex = RegExp(
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
);

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.timer = 0;
    }

    state = {
        userInfo: {
            email: '',
            phone_number: '',
            password: '',
            repeatPassword: ''
        },
        formErrors: {
            captcha: "",
            email: "",
            password: "",
            repeatPassword: ''
        },
        refs: {
            captcha: createRef()
        },
        captcha: "",
        error: {},
        verifyCode: '',
        loading: false,
        success: false,
        activeStep: 0,
        formValid: {},
        formToken: '',
        userInputName: 'emailOrPhone',
        time: {},
        seconds: "",
        resendLeftSecs: 120,
        showRepeatPassword: false,
        showPassword: false

    };
    timer = undefined;

    handleReset = () => {
        this.setState({
            activeStep: 0,
            email: '',
            password: ''
        });
    };

    handleNext = (e) => {
        e.preventDefault();
        const {forgetPasswordStep} = this.props;
        if (forgetPasswordStep === 1) {
            const {userInfo, userInputName, captcha, formErrors} = this.state;
            if (!!formErrors[userInputName]) {
                showError(FORGETPASSWORD, 'form.invalid');
                return;
            }

            let fields = {
                captcha: captcha,
                // email: userInfo.email,
                // phone_number: userInfo.phone_number
                terminal_token: BRANCH_ESSENTIALS.TERMINAL_TOKEN,
                terminal_id: BRANCH_ESSENTIALS.TERMINAL_ID
            };
            fields[userInputName] = userInfo[userInputName];
            this.props.userForgetPassword(fields);
        } else if (forgetPasswordStep === 2) {
            const {userInfo, userInputName, verifyCode, formErrors} = this.state;
            if (!!formErrors.password || !!formErrors.repeatPassword) {
                showError(FORGETPASSWORD, 'form.invalid');
                return;
            }
            this.props.verifyUserForgotPassword({
                token: this.props.forgetPasswordVerifyDetails.token,
                code: verifyCode,
                password: userInfo.password
            });

        }
    };

    startTimer() {
        const {forgetPasswordVerifyDetails} = this.props;
        const now = Date.now() / 1000;
        if (forgetPasswordVerifyDetails && forgetPasswordVerifyDetails.next_request > now && !this.timerStarted) {
            this.timer = setInterval(() => {
                const resendLeftSecs = this.props.forgetPasswordVerifyDetails.next_request - parseInt(Date.now() / 1000);
                this.setState({resendLeftSecs: resendLeftSecs});
                if (resendLeftSecs === 0)
                    this.stopTimer();
            }, 1000);
            this.timerStarted = true;
        }

    }

    stopTimer() {
        if (this.timerStarted && this.timer) {
            clearInterval(this.timer);
            this.timerStarted = false;
        }
    };

    componentWillUnmount() {
        this.stopTimer();
    }


    handleBack = () => {
        const {forgetPasswordStep} = this.props;
        this.props.setForgotPasswordStep(forgetPasswordStep - 1);
        this.setState({
            email: "",
            phone_number: "",
        });
    };

    changeVerifyCode = value => {
        let formErrors = {...this.state.formErrors};
        if (!isNumeric({input: value}))
            formErrors.verifyCode = <IntlMessages id="error.content.verify_code.numeric"/>;
        else if (!lengthBetween({input: value, min: 6, max: 6}))
            formErrors.verifyCode = <IntlMessages id="error.content.verify_code.length"/>;
        else
            formErrors.verifyCode = "";
        this.setState({formErrors, 'verifyCode': value},);
    }

    handleChange = e => {
        // localStorage.clear();
        e.preventDefault();
        let {name, value} = e.target;
        let formErrors = {...this.state.formErrors};
        value = fa2enNumbers(value);

        switch (name) {
            case this.state.userInputName:
                formErrors.email = '';
                if (emailRegex.test(value)) {
                    this.state.userInputName = 'email';
                    this.state.userInfo.email = value;
                }
                // else if (isMobile({input: value})) {
                //     this.state.userInputName = 'phone_number';
                //     this.state.userInfo.phone_number = value;
                // }
                else {
                    formErrors.email = <IntlMessages id="error.content.email_mobile.invalid"/>;
                    this.state.userInputName = 'emailOrPhone';
                    this.state.userInfo.phone_number = '';
                    this.state.userInfo.email = '';
                }
                break;
            case "password":
                formErrors.password =
                    value.length < 8 ? <IntlMessages id="error.content.password.length"/> : "";
                // if(formErrors.repeatPassword)
                formErrors.repeatPassword = (this.state.userInfo['repeatPassword'] === value ?
                    "" :
                    <IntlMessages id="error.content.password_repeat.not_matched"/>);
                this.state.userInfo.password = value;
                break;
            case "repeat-password":
                formErrors.repeatPassword = (this.state.userInfo.password === value ?
                    "" :
                    <IntlMessages id="error.content.password_repeat.not_matched"/>);
                this.state.userInfo.repeatPassword = value;
                break;
            case "captcha":
                if(value.toString().length !== 6)
                    formErrors.captcha = <IntlMessages id="error.content.captcha.length"/>;
                else
                    formErrors.captcha = '';
                break;
            case "verifyCode":
                formErrors.verifyCode =
                    value.length < 5 ? <IntlMessages id="error.content.auth_code.invalid"/> : "";
                break;
            default:
                break;
        }
        this.setState({formErrors, [name]: value},);
    };

    secondsToTime(secs) {
        let seconds = secs;

        let obj = {
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({time: timeLeftVar});
    }

    handleResendCode = (e) => {
        e.preventDefault();
        this.props.resendForgetPassword({
            token: this.props.forgetPasswordVerifyDetails.token,
            // terminal_token: BRANCH_ESSENTIALS.TERMINAL_TOKEN,
            // terminal_id: BRANCH_ESSENTIALS.TERMINAL_ID
        });
    };

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (this.state.seconds == 0) {
            clearInterval(this.timer);
            // return <><Button color="secondary">jdfhjh</Button> </>
        }
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    updateRecaptcha = () => {
        this.props.updateCaptchaVersion();
    }

    getVerifyCode() {
        const {forgetPasswordVerifyDetails} = this.props;
        const {darkTheme} = this.props;
        return (
            <>
                <div className="enter-code-text">
                    {forgetPasswordVerifyDetails.field == "mobile" ?
                        <><IntlMessages id="content.auth.verify_mobile.desc1"/> <span className="mobile-num">{forgetPasswordVerifyDetails.mobile}</span> <IntlMessages id="content.auth.verify_email.enter"/></>
                        : forgetPasswordVerifyDetails.field == "email" ?
                            <><IntlMessages id="content.auth.verify_email.desc1"/> <span className="mobile-num">{forgetPasswordVerifyDetails.email}</span><IntlMessages id="content.auth.verify_email.enter"/></>
                            : ""
                    }
                </div>
                {/*    <div className="d-inline-flex">*/}
                {/*        <div className="jr-fs-xl text-danger">*/}
                {/*            اعتبار تا :*/}
                {/*        </div>*/}
                {/*        <span className="text-primary jr-fs-xl">*/}
                {/*    {" " + forgetPasswordVerifyDetails.code_validity / 60 + " " + "دقیقه" + " "}*/}
                {/*</span>*/}
                {/*    </div>*/}
                <div className="confirm-code-input">
                    <ReactCodeInput
                        value={this.state.verifyCode}
                        inputRef={this.state.refs.code}
                        id="verifyCode"
                        name="verifyCode"
                        // label={<IntlMessages id="title.verify_code"/>}
                        // fullWidth
                        // value={inputs.verifyCode}
                        margin="normal"
                        onChange={this.changeVerifyCode}
                        // onChange={this.changeVerifyCode}
                        error={this.state.error.verifyCode}
                        // error={!!formErrors.verifyCode}
                        // className={formErrors.verifyCode ? "error" : null}
                        type='number' fields={6}
                    />
                </div>
                {/*<TextField*/}
                {/*    type="text"*/}
                {/*    id="verifyCode"*/}
                {/*    name="verifyCode"*/}
                {/*    label={'کد تایید'}*/}
                {/*    autoComplete='off'*/}
                {/*    fullWidth*/}
                {/*    autoFocus={true}*/}
                {/*    inputProps={{tabIndex: "1"}}*/}
                {/*    // defaultValue={this.state.password}*/}
                {/*    // onChange={(event) => this.setState({password: event.target.value})}*/}
                {/*    margin="normal"*/}
                {/*    className="mt-1"*/}
                {/*    onChange={this.handleChange}*/}
                {/*    error={this.state.error.verifyCode}*/}
                {/*    variant="outlined"*/}
                {/*    size="small"*/}
                {/*/>*/}
                {this.state.resendLeftSecs === 0 ?
                    <div className="resend-text-link" onClick={this.handleResendCode}>
                        {/*<Link to="" >*/}
                        <IntlMessages id="title.resend_code"/>
                        {/*</Link>*/}
                    </div>

                    :
                    <div className="seconds-text">
                        {this.state.resendLeftSecs === 0 ? <IntlMessages id="title.resend_code"/> : (<><IntlMessages id="title.until_resend_code"/>{" " + this.state.resendLeftSecs + " "}<IntlMessages id="title.seconds"/></>)}
                    </div>
                }
                {forgetPasswordVerifyDetails.field == "email" ?
                    <div
                        className="check-email">
                        <IntlMessages id="content.check_spams"/>
                    </div>
                    : ""
                }
                <div className="password-input">
                    <TextField
                        type={this.state.showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        // label={<IntlMessages id="title.new_password"/>}
                        fullWidth
                        inputProps={{tabIndex: "2"}}
                        // defaultValue={this.state.password}
                        // onChange={(event) => this.setState({password: event.target.value})}
                        margin="normal"
                        className={"mt-1 " + (this.state.formErrors.password ? "error" : '')}
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.password}
                        variant="outlined"
                        size="small"
                        placeholder={global.locale.locale === 'fa' ? 'رمزعبور جدید' : 'New Password'}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        InputProps={{

                            endAdornment:
                                <IconButton
                                    tabIndex={-1}
                                    onClick={() => {
                                        this.handleClickShowPassword(1)
                                    }}
                                    onMouseDown={this.handleMouseDownPassword}
                                    style={{marginRight: "8px", padding: "0"}}
                                >
                                    {this.state.showPassword ? <img src={require("../assets/images/mars/hide.svg")}/> :
                                        <img src={require("../assets/images/mars/show.svg")}/>}

                                </IconButton>


                        }}
                    />
                    {this.state.formErrors.password && (
                        <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.password}</div>
                    )}
                </div>
                <div className="password-input">
                    <TextField
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        type={this.state.showRepeatPassword ? 'text' : 'password'}
                        id="repeat-password"
                        name="repeat-password"
                        // label={<IntlMessages id="title.re_new_password"/>}
                        fullWidth
                        inputProps={{tabIndex: "3"}}
                        // defaultValue={this.state.password}
                        // onChange={(event) => this.setState({password: event.target.value})}
                        margin="normal"
                        className={"mt-1 " + (this.state.formErrors.repeatPassword ? "error" : '')}
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.repeatPassword}
                        variant="outlined"
                        size="small"
                        placeholder={global.locale.locale === 'fa' ? 'تکرار رمزعبور' : 'Password Repeat'}
                        InputProps={{

                            endAdornment:
                                <IconButton
                                    tabIndex={-1}
                                    onClick={() => {
                                        this.handleClickShowPassword(2)
                                    }}
                                    onMouseDown={this.handleMouseDownPassword}
                                    style={{marginRight: "8px", padding: "0"}}
                                >
                                    {this.state.showRepeatPassword ? <img src={require("../assets/images/mars/hide.svg")}/> :
                                        <img src={require("../assets/images/mars/show.svg")}/>}

                                </IconButton>


                        }}
                    />
                    {this.state.formErrors.repeatPassword && (
                        <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.repeatPassword}</div>
                    )}
                </div>
                <div style={{ display: "none"}}>
                    <TextField
                        type="hidden"
                        id="verifyCode"
                        name="verifyCode"
                        value={this.state.formToken}

                    />
                </div>


            </>

        )
    }

    getUserInfo() {
        return (

            <>
                <BrowserAlert/>
                <div className="email-phone-input">
                    <TextField
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        id="emailOrPhoneNo"
                        // label={<IntlMessages id="appModule.email-or-mobile"/>}
                        // value={this.state.userInfo.email}
                        fullWidth
                        inputProps={{tabIndex: "1"}}
                        autoFocus={true}
                        margin="normal"
                        className={this.state.formErrors.email ? "error" : null}
                        placeholder={global.locale.locale === 'fa' ? 'ایمیل' : 'Email'}
                        type="text"
                        name={this.state.userInputName}
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.email}
                        variant="outlined"
                        size="small"
                    />
                    {this.state.formErrors.email && (
                        <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.email}</div>

                    )}
                </div>
                <div className="captcha-row">
                    <div className="captcha-input">
                        <TextField
                            placeholder={global.locale.locale === 'fa' ? 'کد امنیتی' : 'Captcha'}
                            id="captcha"
                            fullWidth
                            inputRef={this.state.refs.captcha}
                            inputProps={{tabIndex: "2"}}
                            autoComplete='off'
                            margin="normal"
                            className={this.state.formErrors.captcha ? "error" : null}
                            type="number"
                            name='captcha'
                            noValidate
                            onChange={this.handleChange}
                            error={!!this.state.formErrors.captcha}
                            variant="outlined"
                            size="small"
                        />
                        {this.state.formErrors.captcha  && (
                            <div className="small text-danger" style={{margin: '0 8px -8px 0'}}>{this.state.formErrors.captcha}</div>
                        )}
                    </div>
                    <div className='captcha-img-wrapper'>
                        <div className="captcha-refresh-btn">
                            <IconButton
                                tabIndex={-1}
                                onClick={this.updateRecaptcha}
                            >
                                <RefreshIcon/>
                            </IconButton>
                        </div>
                        <div className="captcha-img">
                            <img src={'/core/api/captcha/forgot?v=' + this.props.captchaVersion}
                                 alt='Forgot Password Captcha'/>
                        </div>
                    </div>



                </div>
            </>
        )


    }

    handleClickShowPassword = (id) => {
        switch (id) {
            case  1 :
                this.setState({showPassword: !this.state.showPassword});
                break;
            case 2 :
                this.setState({showRepeatPassword: !this.state.showRepeatPassword});
                break;


        }

    };

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 1:
                this.stopTimer();
                return this.getUserInfo();
            case 2:
                this.startTimer();
                return this.getVerifyCode();
            default:
                return <IntlMessages id="appModule.loginSuccessfully"/>;
        }
    }

    render() {
        const steps = 2;
        const {activeStep} = this.state;
        const {loading, success} = this.state;
        const {classes, forgetPasswordStep, sendingForgetPasswordRequest} = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: success,
        });
        return (
            <>

                <div className="login-wrapper">
                    {/*<div className="login-header-wrapper">*/}
                    {/*    /!*<IntlMessages id="title.forget-password"/>*!/*/}
                    {/*    <div>*/}
                    {/*        فراموشی رمز عبور | خوش آمدید!*/}
                    {/*    </div>*/}
                    {/*    {forgetPasswordStep === 1 ?*/}
                    {/*        <> برای ادامه ایمیل خود را وارد نمایید:</>*/}
                    {/*        : ""}*/}


                    {/*</div>*/}
                    <div className="login-form">
                        <form>
                            <fieldset className={forgetPasswordStep === 2 ? "second-step" : "forget-fieldset"}>
                                {this.getStepContent(forgetPasswordStep)}
                                {forgetPasswordStep === 1 ?
                                    <div className="button-wrapper">
                                        <Button
                                            variant="contained"
                                            className=" confirm-btn"
                                            disabled={sendingForgetPasswordRequest}
                                            onClick={this.handleNext}
                                            fullWidth
                                            type="submit"
                                        >
                                            <IntlMessages id="appModule.send"/>
                                        </Button>
                                        {sendingForgetPasswordRequest &&
                                            <CircularProgress style={{zIndex: '9'}} size={24}
                                                              className={classes.buttonProgress}/>}
                                    </div>
                                    :
                                    <div className="buttons-wrapper">
                                        <div className="confirm-btn-wrapper">
                                            <Button
                                                variant="contained"
                                                className=" confirm-btn"
                                                disabled={sendingForgetPasswordRequest}
                                                onClick={this.handleNext}
                                                fullWidth
                                                type="submit"
                                            >
                                                <IntlMessages id="appModule.verify"/>
                                            </Button>
                                            {sendingForgetPasswordRequest &&
                                                <CircularProgress style={{zIndex: '9'}} size={24}
                                                                  className={classes.buttonProgress}/>}
                                        </div>
                                        <div className="cancel-btn-wrapper">
                                            <Button
                                                onClick={this.handleBack}
                                                className="cancel-btn btn-disable"
                                                fullWidth
                                            >
                                                <IntlMessages id="appModule.back"/>
                                            </Button>
                                        </div>
                                    </div>
                                }
                                {forgetPasswordStep === 1 ?
                                    <div className="forget-password-text">
                                        <IntlMessages id="content.already_signup"/>
                                        <div className="forget-link"
                                             onClick={() => this.props.authModeSelector("login")}><IntlMessages id="title.please_signin"/></div>

                                        {/*<Link to="/reset-password">*/}
                                        {/*    رمز خود را فراموش کرده اید؟*/}
                                        {/*</Link>*/}
                                    </div>
                                    : ""}
                            </fieldset>
                        </form>
                    </div>
                </div>

            </>
        );
    }
}

// export default withStyles(styles)(ForgotPassword);
const mapStateToProps = ({auth, settings}) => {
    const {darkTheme} = settings;
    const {
        sendingForgetPasswordRequest,
        tokenReceiveTime,
        forgetPasswordStep,
        forgetPasswordVerifyDetails,
        captchaVersion
    } = auth;
    return {
        sendingForgetPasswordRequest,
        tokenReceiveTime,
        forgetPasswordStep,
        forgetPasswordVerifyDetails,
        captchaVersion,
        darkTheme
    };
};

export default compose(
    connect(
        mapStateToProps,
        {
            userForgetPassword,
            verifyUserForgotPassword,
            setForgotPasswordStep,
            updateCaptchaVersion,
            resendForgetPassword,
            authModeSelector
        },
    ),
    withStyles(styles)
)(ForgotPassword)






