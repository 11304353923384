import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import {connect} from 'react-redux'
import SwipeableViews from 'react-swipeable-views';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton'
import conversationList from '../../../panel/routes/data/conversationList';
import users from '../../../panel/routes/data/chatUsers';
import CustomScrollbars from 'util/CustomScrollbars';
import {
    closeTicket,
    getTicketMessages,
    getTicketsList,
    submitTicketMessage,
    switchTicketsDrawer,
    unselectTicket
} from "../../../actions/SupportResource";
import {NotificationManager} from "react-notifications";
import ComposeDialog from "./composeDialog";
import TicketChatList from "../../../panel/components/TicketList";
import Conversation from "./components/conversation";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import LinearProgressWithLabel from "../../../panel/routes/components/linearProgressWithLabel";
import CommonQuestions from "../../../panel/routes/components/commonQuestions";
import {fa2enNumbers, toPersianDate} from "../../../util/Utilities";
import {MANAGEMENT_UNITS, STATUS} from "../../../panel/routes/data/constants";
import {toggleContentFixed} from "../../../actions";
import md5 from "md5";
import {SUPPORT} from "../../../constants/Units";
import IntlMessages from "../../../util/IntlMessages";
import createNotification from "../../../util/Notifier";
import "./support.css"
import {withStyles} from "@material-ui/styles";
import {registerPriceListener, ticketTyping} from "../../../util/Socket";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import More from "@material-ui/icons/More";
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {AppBar} from "@material-ui/core";
import PropTypes from "prop-types";

const acceptableExtensions = ['jpeg', 'png', 'jpg', 'pdf', 'rar', 'zip'];
const maxFileSize = 15728640;
const MAX_MESSAGE_LENGTH = 2048;
const styles = theme => ({
    MuiDrawerPaper: {
        overflowY: 'unset'
    },
    sideNavDrawer: {
        width: '310px ',
        '@media (max-width: 650px)': {
            width: '200px'
        },

    },


});

function TicketTabs(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className="tab-inside">
                    <div className="tab-inside-inner-wrapper">{children}</div>
                </div>
            )}
        </div>
    );
}

TicketTabs.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class Support extends Component {


    constructor(props) {
        super(props);
        this.intervalTicketList();
        this.state = {
            activeTab: 0,
            openMoreInfo: false,
            // tickets: [],
            userNotFound: 'No user found',
            selectedSectionId: '',
            selectedTabIndex: 0,
            userState: 1,
            searchChatUser: '',
            filters: Object.values(STATUS),
            file: props.ticketSubmitted || props.ticketSubmitFailed ? false : "",
            contactList: [],
            content: '',
            chatUsers: users.filter((user) => user.recent),
            conversationList: conversationList,
            conversation: null,
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            activeInterval: {
                'ticket': null,
                'handler': null,
            },
            user: {
                name: 'Robert Johnson',
                email: 'robert@example.com',
                avatar: 'https://dummyimage.com/150.png/09f/fff'
            },
            windowHeight: window.outerHeight
        }

        // window.removeEventListener('resize', this.resizeListener);
        // window.addEventListener('resize', this.resizeListener)
    }

    resizeListener = () => {
        this.setState({windowHeight:  window.outerHeight})
    }

    componentDidMount() {
        registerPriceListener(this.props.dispatch, false);
    }


    componentWillMount() {
        if (!this.props.isContentFixed)
            this.props.toggleContentFixed(true);
    }

    componentWillUnmount() {
        if (this.props.isContentFixed)
            this.props.toggleContentFixed(false);
    }

    filterContact = (userName) => {
        if (userName === '') {
            return users.filter(user => !user.recent);
        }
        return users.filter((user) =>
            !user.recent && user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
        );
    };

    filterUsers = (userName) => {
        if (userName === '') {
            return users.filter(user => user.recent);
        }
        return users.filter((user) =>
            user.recent && user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
        );
    };

    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        let succeed = true;
        var file = event.target.files[0];
        if (!file)
            return;
        if (!['image/jpeg', 'image/jpg', 'image/png', 'application/x-compressed', 'application/pdf'].includes(file.type)) {
            createNotification('error', <IntlMessages
                id={'message.title.' + SUPPORT}/>, 'فرمت های مجاز برای ارسال: ' + acceptableExtensions.join(', '))
            succeed = false;
        }
        if (file.size > maxFileSize) {
            createNotification('error', <IntlMessages
                id={'message.title.' + SUPPORT}/>, 'حداکثر حجم مجاز مجاز برای ارسال: ' + (maxFileSize / 1024 / 1024))
            succeed = false;
        }
        if (succeed)
            this.setState({file: file});

        // this.setState({file}); /// if you want to upload latter
    }
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    submitComment(e) {
        e.preventDefault()
        const {content, file} = this.state;
        const {selectedTicket} = this.props;
        const formData = new FormData();
        if (content !== '') {
            formData.append('ticket_id', selectedTicket.id);
            formData.append('management_unit', selectedTicket.management_unit);
            formData.append('content', content);
            // formData.append('file', file);
            // sendTicketFile(file);
            if (this.state.file) {
                formData.append('file', file);
                //TICKET TEST
                // try {
                //     let extension = file.name.split('.').pop();
                //     this.toBase64(file).then((res) => {
                //         console.log('send file:', res);
                //         // formData.append('file', res);
                //         // this.props.submitTicketMessage(formData);
                //         sendTicketFile2(res, md5(res), 'ticket', extension, file.size);
                //     });
                //
                // } catch(error) {
                //     console.error('send file:', error);
                //     return;
                // }
                //END TICKET TEST

            }
            // else
            //     this.props.submitTicketMessage(formData);
            this.props.submitTicketMessage(formData);

        }
    };

    onCloseTicket(id) {
        this.props.closeTicket(id)
    }

    handleClick() {
        const {openMoreInfo} = this.state;
        this.setState({openMoreInfo: !openMoreInfo});
    }

    getDesktopHeader = (selectedTicket) => {
        return (
            <>
                <div className=" jr-fs-lg">
                    <div className="col-lg-12  text-nowrap">
                        <div className="col-lg-6">
                            <div className="  m-1">
                                <div>
                                                <span className="text-secondary font-weight-bold">
                                               موضوع:
                                                </span>
                                    <span className="ml-1">
                                                    {selectedTicket.title.length > 24 ? selectedTicket.title.substring(0, 8) + '...' : selectedTicket.title}
                                                </span>
                                </div>
                            </div>
                            <div className="  m-1">
                                <div>
                                                <span className="text-secondary font-weight-bold">
                                                   تاریخ ایجاد:
                                                </span>
                                    <span className="small ml-1">
                                                        {toPersianDate(selectedTicket.created_at)}
                                                </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="  m-1">
                                <div>
                                                <span className="text-secondary font-weight-bold">
                                                    واحد:
                                                </span>
                                    <span className="ml-1">
                                                    {MANAGEMENT_UNITS[selectedTicket.management_unit]}
                                                </span>
                                </div>
                            </div>
                            <div className="  m-1">
                                <div>
                                               <span className="text-secondary font-weight-bold">
                                                   تاریخ آخرین تغییر:
                                               </span>
                                    <span className="small ml-1">
                                                   {toPersianDate(selectedTicket.updated_at)}
                                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {![STATUS.REJECTED_BY_EX_API, STATUS.REJECTED_BY_ADMIN, STATUS.REJECTED_BY_ADMIN, STATUS.REJECTED_BY_USER].includes(selectedTicket.status) ? (
                    <div className="chat-close-btn-wrapper">

                        <Button  variant="contained"
                                className="chat-close-btn"
                                aria-label="Close Chat"
                                onClick={() => this.onCloseTicket(selectedTicket.id)}>
                            <IntlMessages id="button.description.close-ticket"/>
                            {/*<Menu/>*/}
                        </Button>
                    </div>

                ) : ''}
            </>
        )
    }

    getMobileHeader = (selectedTicket) => {
        const {openMoreInfo} = this.state;
        return (
        <div className="col-lg-8 col-sm-8" style={{padding: "8px 10px 0 0"}}>
            <List className="p-0"
                  component="nav"
                  aria-labelledby="nested-list-subheader"

            >
                <ListItem button
                          onClick={() => {
                              this.handleClick()
                          }} className="border-primary" style={{
                    borderRadius: "15px 15px 15px 15px",
                    border: "2px solid",
                    marginTop: "2px"
                }}>
                    <ListItemIcon>
                        <More/>
                    </ListItemIcon>
                    <ListItemText className="jr-fs-lg" primary={selectedTicket.title.length > 16 ? selectedTicket.title.substring(0, 16) + '...' : selectedTicket.title}/>
                    {openMoreInfo ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={openMoreInfo} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button
                                  style={{backgroundColor: "rgb(236 236 236)", borderRadius: "5px", marginBottom: '10px', zIndex: 2}}>
                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div>
                                                                <span className="text-secondary">
                                                                    موضوع:
                                                                </span>
                                        <span className="ml-1">
                                                                    {selectedTicket.title.length > 32 ? selectedTicket.title.substring(0, 32) + '...' : selectedTicket.title}
                                                                </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div>
                                                                <span className="text-secondary">
                                                                    واحد:
                                                                </span>
                                        <span className="ml-1">
                                                                    {MANAGEMENT_UNITS[selectedTicket.management_unit]}
                                                                </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div>
                                                                <span className="text-secondary">
                                                                   تاریخ ایجاد:
                                                                </span>
                                        <span className="small ml-1">
                                                                        {toPersianDate(selectedTicket.created_at)}
                                                                </span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div>
                                                                <span className="text-secondary">
                                                                   تاریخ آخرین تغییر:
                                                                </span>
                                        <span className="small ml-1">
                                                                    {toPersianDate(selectedTicket.updated_at)}
                                                                </span>
                                    </div>
                                </div>
                                {![STATUS.REJECTED_BY_EX_API, STATUS.REJECTED_BY_ADMIN, STATUS.REJECTED_BY_ADMIN, STATUS.REJECTED_BY_USER].includes(selectedTicket.status) ? (
                                    <div className="d-flex justify-content-center align-center m-auto p-2">

                                        <Button style={{height: "35px"}} variant="contained"
                                                className="btn-secondary jr-fs-lg chat-btn ml-0 p-1" color='error'
                                                aria-label="Menu"
                                                onClick={() => this.onCloseTicket(selectedTicket.id)}>
                                            <IntlMessages id="button.description.close-ticket"/>
                                            {/*<Menu/>*/}
                                        </Button>
                                    </div>

                                ) : ''}
                            </div>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </div>)
    }

    Communication = () => {
        const {content} = this.state;
        const {messages, selectedTicket, currentUser, token, progress, submittingMessage} = this.props;
        const hashedToken = md5(token ?? '');
        return (

                <div className="chat-box"
                     // style={{maxHeight: 'calc(100vh - 60px)', overflow: 'auto'}}
                >
                    <div className="chat-box-main">
                        <div className="chat-main"
                             // style={{height: 'calc(100vh - 62px)', backgroundColor: '#7530ff'}}
                        >

                            <div className="chat-main-header">
                                    <div className="chat-main-header-inner-wrapper">
                                        <div className="chat-back-btn">
                                            <IconButton onClick={this.onFirstPage.bind(this)}
                                                        className="back-btn"
                                                        aria-label="Menu">
                                                <SubdirectoryArrowRightIcon/>
                                            </IconButton>
                                            {/*<div className="chat-avatar-mode">*/}
                                            {/*    <img alt="alt" width='50'*/}
                                            {/*         src={currentUser.selfie_verified === STATUS.ACCEPTED_BY_ADMIN ? `/core/api/storage/profile/${currentUser.id}/${hashedToken}/` : userAvatar}/>*/}

                                            {/*    <span className="chat-mode online"*/}
                                            {/*        // className={`chat-mode ${selectedTicket.status}`}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="chat-header-info">
                                            <div className="chat-info-row">
                                                <div className="info-title">
                                                    موضوع:
                                                </div>
                                                <div className="info-value">
                                                    {selectedTicket.title.length > 24 ? selectedTicket.title.substring(0, 8) + '...' : selectedTicket.title}

                                                </div>
                                            </div>
                                            <div className="chat-info-row">
                                                <div className="info-title">
                                                    واحد:
                                                </div>
                                                <div className="info-value">
                                                    {MANAGEMENT_UNITS[selectedTicket.management_unit]}
                                                </div>
                                            </div>
                                            <div className="chat-info-row">
                                                <div className="info-title">
                                                    تاریخ ایجاد:
                                                </div>
                                                <div className="info-value">
                                                    {toPersianDate(selectedTicket.created_at)}
                                                </div>
                                            </div>
                                            <div className="chat-info-row">
                                                <div className="info-title">
                                                    تاریخ آخرین تغییر:
                                                </div>
                                                <div className="info-value">
                                                    {toPersianDate(selectedTicket.updated_at)}
                                                </div>
                                            </div>
                                        </div>
                                        {![STATUS.REJECTED_BY_EX_API, STATUS.REJECTED_BY_ADMIN, STATUS.REJECTED_BY_ADMIN, STATUS.REJECTED_BY_USER].includes(selectedTicket.status) ? (
                                            <div className="chat-close-btn-wrapper">

                                                <Button  variant="contained"
                                                         className="chat-close-btn"
                                                         aria-label="Close Chat"
                                                         onClick={() => this.onCloseTicket(selectedTicket.id)}>
                                                    <IntlMessages id="button.description.close-ticket"/>
                                                    {/*<Menu/>*/}
                                                </Button>
                                            </div>

                                        ) : ''}
                                    </div>

                                    {/*{width <= MOBILE_MAX_WIDTH ? this.getMobileHeader(selectedTicket) : this.getDesktopHeader(selectedTicket)}*/}
                            </div>

                            <CustomScrollbars className="chat-list-scroll scrollbar">

                                <Conversation conversationData={messages} currentUser={currentUser}
                                              selectedTicket={selectedTicket} hashedToken={hashedToken}/>


                            </CustomScrollbars>
                            {Object.keys(selectedTicket).length === 0 ? "" :
                                <div className={"chat-main-footer"}
                                     style={{position: "fixes", bottom: "0%"}}>
                                    {submittingMessage ? <LinearProgressWithLabel progress={progress}/> : ""}
                                    <div className="ticket-compose-wrapper">
                                        <div className="buttons-wrapper">
                                            <IconButton
                                                onClick={() => {
                                                    this.upload.click()
                                                }}
                                                aria-label="Send attachment" className={"file-btn"}>
                                                <i className="zmdi zmdi-attachment"/>
                                            </IconButton>
                                            <IconButton disabled={submittingMessage} className="submit-btn"
                                                        onClick={this.submitComment.bind(this)}
                                                        aria-label="Send message">
                                                <i className="zmdi  zmdi-mail-send"/>
                                            </IconButton>


                                            {/*<Button className="file-btn"   onClick={() => {*/}
                                            {/*    this.upload.click()*/}
                                            {/*}}>*/}
                                            {/*    فایل*/}

                                            {/*</Button>*/}
                                            <input type="file" accept='.jpeg, .png, .jpg, .pdf, .rar, .zip'
                                                   ref={(ref) => this.upload = ref} style={{display: 'none'}}
                                                   onChange={this.onChangeFile.bind(this)}>
                                            </input>
                                            {/*<Button className="submit-btn" isabled={submittingMessage}*/}
                                            {/*        onClick={this.submitComment.bind(this)}*/}
                                            {/*        aria-label="Send message">*/}
                                            {/*    ارسال*/}

                                            {/*</Button>*/}

                                        </div>
                                        <div className="textarea-wrapper">
                                            <TextareaAutosize
                                                id="required"

                                                className="chat-text-area"
                                                // style={(width < MOBILE_MAX_WIDTH ? {height: '145px'} : {})}
                                                // onKeyUp={this._handleKeyPress.bind(this)}
                                                onChange={this.updateMessageValue.bind(this)}
                                                value={content}
                                                placeholder="توجه داشته باشید شما تنها 3 پیام میتوانید قبل از پاسخ توسط همکاران ما ارسال نمایید درخواست خود را به صورت کامل ارسال کنید...."
                                                variant="contained"
                                            />
                                        </div>

                                    </div>
                                    <div className="chat-bottom-wrapper">
                                        <div className={"chat-limit"}>{MAX_MESSAGE_LENGTH - content.length} / {MAX_MESSAGE_LENGTH}</div>
                                        {this.state.file ?
                                            <div className="file-badge" >
                                                {this.state.file.name}
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({file: null})
                                                    }}
                                                    aria-label="Send attachment" className="close-badge">
                                                    <i className="zmdi zmdi-close-circle"/>
                                                </IconButton></div> : ""

                                        }
                                    </div>

                                    {/*<div className={"d-flex flex-row align-items-center my-1 " + (width < 480 ? 'py-1' : '')}>*/}

                                    {/*    <div className="chat-sent pl-2">*/}
                                    {/*        <IconButton disabled={submittingMessage} className="text-white p-2"*/}
                                    {/*                    onClick={this.submitComment.bind(this)}*/}
                                    {/*                    aria-label="Send message">*/}
                                    {/*            <i className="zmdi  zmdi-mail-send"/>*/}
                                    {/*        </IconButton>*/}
                                    {/*        <IconButton*/}
                                    {/*            onClick={() => {*/}
                                    {/*                this.upload.click()*/}
                                    {/*            }}*/}
                                    {/*            aria-label="Send attachment" className={"text-white p-2" + (width < 480 ? 'mt-2' : '')}>*/}
                                    {/*            <i className="zmdi zmdi-attachment"/>*/}
                                    {/*        </IconButton>*/}

                                    {/*        <p className={'ltr'}>{MAX_MESSAGE_LENGTH - content.length} / {MAX_MESSAGE_LENGTH}</p>*/}
                                    {/*        <input type="file" accept='.jpeg, .png, .jpg, .pdf, .rar, .zip'*/}
                                    {/*               ref={(ref) => this.upload = ref} style={{display: 'none'}}*/}
                                    {/*               onChange={this.onChangeFile.bind(this)}>*/}
                                    {/*        </input>*/}

                                    {/*    </div>*/}
                                    {/*    <div className={"col px-2 "}>*/}
                                    {/*        /!*{ticketSubmitted ? "" :*!/*/}
                                    {/*        {this.state.file ?*/}
                                    {/*            <div className="badge" style={{*/}
                                    {/*                backgroundColor: "rgb(10, 10, 10 ,0.33)",*/}
                                    {/*                borderRadius: "30px"*/}
                                    {/*            }}>*/}
                                    {/*                {this.state.file.name}*/}
                                    {/*                <IconButton*/}
                                    {/*                    onClick={() => {*/}
                                    {/*                        this.setState({file: null})*/}
                                    {/*                    }}*/}
                                    {/*                    aria-label="Send attachment" className="text-secondary p-1">*/}
                                    {/*                    <i className="zmdi zmdi-close-circle"/>*/}
                                    {/*                </IconButton></div> : ""*/}

                                    {/*        }*/}

                                    {/*        <div className="form-group">*/}

                                    {/*        </div>*/}
                                    {/*    </div>*/}

                                    {/*</div>*/}
                                </div>}

                        </div>
                    </div>
                </div>

        )

    };

    ChatUsers = () => {
        const {tickets} = this.props;
        const {activeTab, filters} = this.state;
        let filteredTickets = tickets.filter((ticket) => filters.includes(ticket.status));
        return (
            <div className="chat-side-nav-wrapper">
                <div className="chat-tabs-section-wrapper">
                    <AppBar position="static" >
                        <Tabs variant="fullWidth" className="tabs-wrapper" value={activeTab} onChange={this.handleChangeTabs}>
                            <Tab fullWidth label={"تمام تیکت ها"}  {...a11yProps(0)} />
                            <Tab  fullWidth label={"پاسخ داده شده"}  {...a11yProps(1)} />
                            <Tab  fullWidth label={"در انتظار"}  {...a11yProps(2)} />
                        </Tabs>
                        <div className="gradient-bottom"/>
                        <div className="borders-wrapper">
                            <img alt="alt" src={require('../../../assets/images/V2/Red vector (1).svg')}/>
                            <img alt="alt" src={require('../../../assets/images/V2/Blue vector.svg')}/>
                        </div>
                    </AppBar>
                    <TicketTabs value={activeTab} index={0}>

                        <ComposeDialog/>
                        <SwipeableViews
                            index={this.state.selectedTabIndex}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            <CustomScrollbars className="chat-sidenav-scroll scrollbar"
                                              >

                                {this.state.chatUsers.length === 0 ?
                                    <div className="p-5">{this.state.userNotFound}</div>
                                    :
                                    filteredTickets.length === 0 ?
                                        <div className="text-black-50  jr-fs-sm"
                                             style={{textAlign: "center", marginTop: "20px"}}> تیکتی برای نمایش وجود
                                            ندارد</div>
                                        :
                                        <TicketChatList tickets={filteredTickets}
                                                        selectedSectionId={this.state.selectedSectionId}
                                                        onSelectUser={this.onSelectUser.bind(this)}
                                        />
                                }
                            </CustomScrollbars>
                        </SwipeableViews>


                    </TicketTabs>
                    <TicketTabs value={activeTab} index={1}>
                        <ComposeDialog/>
                        <SwipeableViews
                            index={this.state.selectedTabIndex}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            <CustomScrollbars className="chat-sidenav-scroll scrollbar"
                                              style={{height: this.props.width >= 1200 ? 'calc(100vh - 175px)' : 'calc(100vh - 100px)'}}>

                                {this.state.chatUsers.length === 0 ?
                                    <div className="p-5">{this.state.userNotFound}</div>
                                    :
                                    filteredTickets.length === 0 ?
                                        <div className="text-black-50  jr-fs-sm"
                                             style={{textAlign: "center", marginTop: "20px"}}> تیکتی برای نمایش وجود
                                            ندارد</div>
                                        :
                                        <TicketChatList tickets={filteredTickets}
                                                        selectedSectionId={this.state.selectedSectionId}
                                                        onSelectUser={this.onSelectUser.bind(this)}
                                        />
                                }
                            </CustomScrollbars>
                        </SwipeableViews>
                    </TicketTabs>
                    <TicketTabs value={activeTab} index={2}>
                        <ComposeDialog/>
                        <SwipeableViews
                            index={this.state.selectedTabIndex}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            <CustomScrollbars className="chat-sidenav-scroll scrollbar"
                                              style={{height: this.props.width >= 1200 ? 'calc(100vh - 175px)' : 'calc(100vh - 100px)'}}>

                                {this.state.chatUsers.length === 0 ?
                                    <div className="p-5">{this.state.userNotFound}</div>
                                    :
                                    filteredTickets.length === 0 ?
                                        <div className="text-black-50  jr-fs-sm"
                                             style={{textAlign: "center", marginTop: "20px"}}> تیکتی برای نمایش وجود
                                            ندارد</div>
                                        :
                                        <TicketChatList tickets={filteredTickets}
                                                        selectedSectionId={this.state.selectedSectionId}
                                                        onSelectUser={this.onSelectUser.bind(this)}
                                        />
                                }
                            </CustomScrollbars>
                        </SwipeableViews>
                    </TicketTabs>
                </div>
            </div>
            )

    };
    handleChangeTabs = (event, newValue) => {
        let filters;
        if(newValue === 1)
            filters = [STATUS.USER_PROGRESS];
        else if(newValue === 2)
            filters = [STATUS.ADMIN_PROGRESS];
        else
            filters = Object.values(STATUS);
        this.setState({activeTab: newValue, filters});
    };

    ChatUsersDrawer = () => {
        const {tickets,} = this.props;
        const {activeTab, filters} = this.state;
        let filteredTickets = tickets.filter((ticket) => filters.includes(ticket.status));
        // console.log('filters:', filters, filteredTickets.length);
        return (
            <div className="chat-side-nav-wrapper">
                <div className="chat-tabs-section-wrapper">
                    <AppBar position="static" >
                        <Tabs variant="fullWidth" className="tabs-wrapper" value={activeTab} onChange={this.handleChangeTabs}>
                            <Tab fullWidth label={"تمام تیکت ها"}  {...a11yProps(0)} />
                            <Tab  fullWidth label={"پاسخ داده شده"}  {...a11yProps(1)} />
                            <Tab  fullWidth label={"در انتظار"}  {...a11yProps(2)} />
                        </Tabs>
                        <div className="gradient-bottom"/>
                        <div className="borders-wrapper">
                            <img alt="alt" src={require('../../../assets/images/V2/Red vector (1).svg')}/>
                            <img alt="alt" src={require('../../../assets/images/V2/Blue vector.svg')}/>
                        </div>
                    </AppBar>
                    <TicketTabs value={activeTab} index={0}>

                            <ComposeDialog/>
                            <SwipeableViews
                                index={this.state.selectedTabIndex}
                                onChangeIndex={this.handleChangeIndex}
                            >
                                <CustomScrollbars className="chat-sidenav-scroll scrollbar"
                                                 >

                                    {this.state.chatUsers.length === 0 ?
                                        <div className="p-5">{this.state.userNotFound}</div>
                                        :
                                        filteredTickets.length === 0 ?
                                            <div className="text-black-50  jr-fs-sm"
                                                 style={{textAlign: "center", marginTop: "20px"}}> تیکتی برای نمایش وجود
                                                ندارد</div>
                                            :
                                            <TicketChatList tickets={filteredTickets}
                                                            selectedSectionId={this.state.selectedSectionId}
                                                            onSelectUser={this.onSelectUser.bind(this)}
                                            />
                                    }
                                </CustomScrollbars>
                            </SwipeableViews>


                    </TicketTabs>
                    <TicketTabs value={activeTab} index={1}>
                        <ComposeDialog/>
                        <SwipeableViews
                            index={this.state.selectedTabIndex}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            <CustomScrollbars className="chat-sidenav-scroll scrollbar"
                                              style={{height: this.props.width >= 1200 ? 'calc(100vh - 175px)' : 'calc(100vh - 100px)'}}>

                                {this.state.chatUsers.length === 0 ?
                                    <div className="p-5">{this.state.userNotFound}</div>
                                    :
                                    filteredTickets.length === 0 ?
                                        <div className="text-black-50  jr-fs-sm"
                                             style={{textAlign: "center", marginTop: "20px"}}> تیکتی برای نمایش وجود
                                            ندارد</div>
                                        :
                                        <TicketChatList tickets={filteredTickets}
                                                        selectedSectionId={this.state.selectedSectionId}
                                                        onSelectUser={this.onSelectUser.bind(this)}
                                        />
                                }
                            </CustomScrollbars>
                        </SwipeableViews>
                    </TicketTabs>
                    <TicketTabs value={activeTab} index={2}>
                        <ComposeDialog/>
                        <SwipeableViews
                            index={this.state.selectedTabIndex}
                            onChangeIndex={this.handleChangeIndex}
                        >
                            <CustomScrollbars className="chat-sidenav-scroll scrollbar"
                                              style={{height: this.props.width >= 1200 ? 'calc(100vh - 175px)' : 'calc(100vh - 100px)'}}>

                                {this.state.chatUsers.length === 0 ?
                                    <div className="p-5">{this.state.userNotFound}</div>
                                    :
                                    filteredTickets.length === 0 ?
                                        <div className="text-black-50  jr-fs-sm"
                                             style={{textAlign: "center", marginTop: "20px"}}> تیکتی برای نمایش وجود
                                            ندارد</div>
                                        :
                                        <TicketChatList tickets={filteredTickets}
                                                        selectedSectionId={this.state.selectedSectionId}
                                                        onSelectUser={this.onSelectUser.bind(this)}
                                        />
                                }
                            </CustomScrollbars>
                        </SwipeableViews>
                    </TicketTabs>
                </div>
            </div>
        )

    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.submittingMessage && !nextProps.submittingMessage && nextProps.ticketSubmitted)
            this.setState({content: '', file: null});
        if (nextProps.selected_ticket)
            this.onSelectUser(nextProps.selected_ticket);
        if (nextProps.ticketsRedraw)
            this.getTicketList();
        if (nextProps.messagesRedraw)
            this.props.getTicketMessages({ticket: nextProps.selectedTicket});
        if(this.props.width !== nextProps.width)
            this.setState({windowHeight:  window.outerHeight})

    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitComment(e);
        }
    };

    handleChange = (event, value) => {
        this.setState({selectedTabIndex: value});
    };

    handleChangeIndex = index => {
        this.setState({selectedTabIndex: index});
    };

    onSelectUser(ticket) {
        this.props.getTicketMessages({ticket: ticket});
        // this.getAllMessages(ticket);
        if (this.state.activeInterval.ticket !== ticket.id) {
            this.state.activeInterval.ticket = ticket.id;
            if (this.state.activeInterval.handler !== null) {
                clearInterval(this.state.activeInterval.handler);
            }
            this.props.switchTicketsDrawer(this.props.drawerState);
        }
    };

    showCommunication = () => {
        const {width} = this.props;
        return (


            <div className="chat-box" >
                {width < 1200 ?
                    <Button className=" tickets-btn  " variant="contained" size="large"
                            onClick={this.onToggleDrawer.bind(this)}>منو تیکت ها</Button>
               :  ""
                }
                <div className="app-wrapper ">

                    <div className="row m-0 ">


                        {/*<div className=" col-lg-11 m-auto float-none pt-4 statics">*/}
                        {/*    <div className="col-lg-4 ">*/}
                        {/*        <ImageCard Title={'همه تیکت ها'}*/}
                        {/*                   cardImg={require("../../../assets/images/summation.png")}*/}
                        {/*                   secoundTitle={stats.total}*/}
                        {/*                   thirdTitle={<>&nbsp;</>} bottom={<>&nbsp;</>}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-lg-4 ">*/}
                        {/*        <ImageCard Title={'پاسخ داده شده'}*/}
                        {/*                   cardImg={require("../../../assets/images/icons8-ok.svg")}*/}
                        {/*                   secoundTitle={stats.admin_answered}*/}
                        {/*                   thirdTitle={'بسته شده'} bottom={stats.closed}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-lg-4 ">*/}
                        {/*        <ImageCard Title={' در انتظار پاسخ'}*/}
                        {/*                   cardImg={require("../../../assets/images/stopwatch.svg")}*/}
                        {/*                   secoundTitle={stats.user_answered}*/}
                        {/*                   thirdTitle={"در حال بررسی"} bottom={stats.in_progress}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-lg-12 m-auto float-none">
                            <CommonQuestions/>
                        </div>
                    </div>

                </div>
            </div>

        )
    };

    createNotification = (type, title, message) => {
        switch (type) {
            case 'info':
                NotificationManager.info('Info message');
                break;
            case 'success':
                NotificationManager.success('Success message', 'Title here');
                break;
            case 'warning':
                NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                break;
            case 'error':
                NotificationManager.error(message, title, 3000, () => {
                    // alert('callback');
                });
                break;
        }
    };

    getTicketList() {
        this.props.getTicketsList({});
    };

    intervalTicketList = () => {
        this.getTicketList();
    };

    updateMessageValue(evt) {
        const {selectedTicket} = this.props;
            ticketTyping(selectedTicket.id , evt.target.value)
            this.setState({
                content: fa2enNumbers(evt.target.value.substring(0, MAX_MESSAGE_LENGTH))
            });
    }

    updateSearchChatUser(evt) {
        this.setState({
            searchChatUser: evt.target.value,
            contactList: this.filterContact(evt.target.value),
            chatUsers: this.filterUsers(evt.target.value)
        });
    }

    onToggleDrawer() {
        this.props.switchTicketsDrawer(!this.props.drawerOpened);
    }

    onFirstPage() {
        // this.props.switchTicketsDrawer(!this.props.drawerOpened);
        this.props.unselectTicket()
    }

    handleRequestClose = () => {
        this.setState({
            composeMail: false,
            showMessage: false,
            folderMenuState: false,
            labelMenuState: false,
            optionMenuState: false
        });
    };

    onMailSend(data) {
        this.setState(
            {
                alertMessage: 'Mail Sent Successfully',
                showMessage: true,
                folderMails: this.state.allMail.concat(data),
                allMail: this.state.allMail.concat(data)
            }
        );
    }

    render() {

        const {selectedTicket, loadingMessages, drawerOpened} = this.props;
        return (
            <div className="">
                <Drawer className="tickets-drawer" classes='' open={drawerOpened}
                        onClose={this.onToggleDrawer.bind(this)}>
                    {this.ChatUsersDrawer()}
                </Drawer>
                {/*<ComposeMail open={this.state.composeMail} user={this.state.user}*/}
                {/*             onClose={this.handleRequestClose.bind(this)}*/}
                {/*             onMailSend={this.onMailSend.bind(this)}/>*/}
                <div className="app-module chat-module animated slideInUpTiny animation-duration-3">
                    <div className="chat-module-box">
                        {/*<div className="d-block d-xl-none">*/}

                        {/*</div>*/}
                        <div className="chat-sidenav d-none d-xl-flex">
                            {this.ChatUsers()}
                        </div>
                        {loadingMessages ?
                            <div className="chat-box loader-view w-100"
                                 style={{height: 'calc(100vh - 120px)'}}>
                                <CircularProgress/>
                            </div> :
                            !(Object.keys(selectedTicket).length) ?
                                this.showCommunication()
                                :
                                this.Communication()
                        }
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = ({settings, support, commonData, auth}) => {
    const {width, isContentFixed} = settings;
    const {currentUser, token} = auth;
    const {hasErrors} = commonData;
    const {
        tickets,
        ticketSubmitted,
        ticketSubmitFailed,
        ticketsRedraw,
        loadingTickets,
        messages,
        loadingMessages,
        selectedTicket,
        messagesRedraw,
        submittingMessage,
        progress,
        stats,
        drawerOpened
    } = support;
    return {
        width, isContentFixed, tickets, ticketsRedraw, loadingTickets, messages,
        loadingMessages, selectedTicket, messagesRedraw, submittingMessage,
        hasErrors, currentUser, token, progress, stats, drawerOpened, ticketSubmitted, ticketSubmitFailed
    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        getTicketsList: (data) => dispatch(getTicketsList(data)),
        closeTicket: (data) => dispatch(closeTicket(data)),
        getTicketMessages: (data) => dispatch(getTicketMessages(data)),
        submitTicketMessage: (data) => dispatch(submitTicketMessage(data)),
        switchTicketsDrawer: (data) => dispatch(switchTicketsDrawer(data)),
        unselectTicket: () => dispatch(unselectTicket()),
        toggleContentFixed: (data) => dispatch(toggleContentFixed(data)),
        dispatch,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Support));