
export const isInt = ({input}) => {
    return (/^\d+$/).test(input);
};

export const isFloat = ({input}) => {
    return (/^-?\d*(\.\d+)?$/).test(input);
};

export const isNumeric = ({input}) => {
    return (/^\d+$/).test(input);
};

export const lengthBetween = ({input, min, max}) => {
    return (input.length >= min && input.length <= max);
};

export const charExists = ({input}) => {
    return (typeof input === "string" && input.length > 0);
};

export const isMobile = ({input, type = "IR"}) => {
    let isValid = false;
    let val = input;
    if(type === 'IR')
        isValid = (input.substring(0, 3) === '+98' && (val = input.substring(3))) || (input.substring(0, 2) === '09' && (val = input.substring(2)));
        return isValid && RegExp('^\\d+$').test(val) && val.length >= 9 && val.length <= 13;
};

export const isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const isValidIPv4 = (ip) => {
    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipv4Regex.test(ip);
};

export const isValidIPv6 = (ip) => {
    const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]|[1-9]?[0-9]))\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]|[1-9]?[0-9]))|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]|[1-9]?[0-9]))\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]|[1-9]?[0-9])))$/i;
    return ipv6Regex.test(ip);
};

export const isValidIP = (ip) => {
    return isValidIPv4(ip) || isValidIPv6(ip);
};


export const idSelected = ({input}) => {
    return input > 0;
}

export const isEmail = ({input}) => {
    return RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(input);
};
export const minimumAmount = ({input}) => {
    return (input >= 1000)
};
export const isName = ({input}) => {
    return (/^[\u0600-\u06FF\s]+$/).test(input);
};
export const isset = (input) =>  {
    return typeof input !== 'undefined';
}
