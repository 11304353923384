import React from "react"
import Button from "@material-ui/core/Button";
import ImageStorage from "../../../assets/ImageStorage";
import IntlMessages from "../../../util/IntlMessages";


class HomeFooter extends React.Component{
    render(){
        return(
            <div className="home-footer-wrapper">
                <div className="home-footer">

                    <div className="about-us-wrapper">
                        {/*<img alt="alt" src={require("../../../assets/images/common/FooterRedBorder.svg")}/>*/}
                        <div className="about-us-inner">
                            <div className="heading">
                                <IntlMessages id='footer.head1'/>
                            </div>
                            <div className="description">

                                <IntlMessages id='footer.desc1'/>   </div>
                        </div>


                        {/*<img alt="alt" src={require("../../../assets/images/common/FooterBlueBorder.svg")}/>*/}
                    </div>
                    <div className="additional-info-wrapper">
                        <div className="additional-info">
                            <div className="info-row">
                                <div className="img-title-wrapper">
                                    <img alt="alt" src={require("../../../assets/images/mars/email-svgrepo-com.svg")}/>
                                    <div className="title">
                                        Email:
                                    </div>
                                </div>
                                <div className="desc">
                                    changiny.com@gmail.com
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="img-title-wrapper">
                                    <img alt="alt"
                                         src={require("../../../assets/images/mars/telegram-logo-svgrepo-com.svg")}/>
                                    <div className="title">
                                        Telegram:
                                    </div>
                                </div>

                                <div className="desc">
                                    @changinyadmin
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="img-title-wrapper">
                                    <img alt="alt"
                                         src={require("../../../assets/images/mars/instagram-outline-svgrepo-com.svg")}/>
                                    <div className="title">
                                        Instagram:
                                    </div>
                                </div>
                                <div className="desc">
                                    changiny.com
                                </div>
                            </div>
                            <div className="info-row">
                                <div className="img-title-wrapper">
                                    <img alt="alt"
                                         src={require("../../../assets/images/mars/whatsapp-color-svgrepo-com.svg")}/>
                                    <div className="title">
                                        Whatsapp:
                                    </div>
                                </div>
                                <div className="desc">
                                    @changinyadmin
                                </div>
                            </div>
                            {/*<div className="info-row">*/}
                            {/*    <div className="img-title-wrapper">*/}
                            {/*        <img alt="alt" src={require("../../../assets/images/V2/footer-phone.svg")}/>*/}
                            {/*        <div className="title">*/}
                            {/*            شماره تماس:*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <div className="desc">*/}
                            {/*        041-91012526*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-bottom-wrapper">
                        <div className="bottom-info">

                        </div>
                        <div className="gradient-bottom"/>
                    </div>
                    <div className="footer-copy-right">
                        Copyright: Changiny.com© 2021

                    </div>
                </div>
            </div>

        )
    }
}
export default HomeFooter;